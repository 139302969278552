.css-1eqf1yw-TagsContainer {
    margin-top: 0px;
}
.css-1tj39sd-Input-Input {
    border: 1px solid #CECECE;
}
.search-input-header {
    @media(max-width:1023px){
        max-width: 92%;
        margin: auto;
    }
}
.search-ep-input + div > div:nth-child(1) {
    padding: 0 50px 0 10px
}

.search-ep-input + div > div:nth-child(2){
    right: 0;
    left: unset;
    background-color: #490E6F;
    border-radius: 4px;
    div > svg {
        fill:#fff;
    }
}

.search-ep-input {
    padding: 2px 15px;
    min-height: 10px;
    font-size: 15px;
    font-family: roboto !important;
    background-color: #F7F7F7;
    @media(max-width:1023px){
        font-size: 15px;
    }
}

.searchfieldep > div > div {
    max-height: 42px;
    box-shadow: unset;
}

.auto-suggestion-resultcontainer {
    position: absolute;
    background: #fff;
    display: table;
    padding: 15px;
    box-shadow: 0.3em 0.3em 1em rgba(0, 0, 0, 0.6);
    border-radius: 4px;
    z-index: 230;
    div {
        margin-bottom: 8px;
    }
    a {
        text-align: left;
        margin: 0;
        display: table;
        font-size: 15px!important;
        text-transform: none!important;
        line-height: 1.2;
        color: #383838!important;
        font-family: roboto!important;
        height: auto!important;
        &::after {
            content: ''!important;
        }
        &:hover {
            text-decoration: underline!important;
            color:#490E6F;
        }
        @media(max-width:767px){
            height: auto;
            margin-bottom: 10px;
        }
    }
    a#autosuggmore {
        text-align: center;
        margin: auto;
    }
}
.auto-suggestion-resultcontainer.hidden,
.css-u9ogal {
    display: none;
}